<template>
  <div>
    <div class="list">
      <ul>
        <li v-for="(item,index) in title_list" :key="index">
        <span ref="spans" :style="{color: activeStep === index ? '#1987e1' : '#000000'}"
              @click="jump(index)">
        {{item.title}}
        </span>
        </li>
      </ul>
    </div>
    <div class="result" @scroll="onScroll" >
      <div class="scroll-item"><span>第一</span></div>
      <div class="scroll-item"><span>第二</span></div>
      <div class="scroll-item"><span>第三</span></div>
      <div class="scroll-item"><span>第四</span></div>
      <div class="scroll-item"><span>第五</span></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "test3",
  methods:{
    jump(index) {
      var items = document.querySelectorAll(".scroll-item");
      for (var i = 0; i < items.length; i++) {
        if (index === i) {
          items[i].scrollIntoView({
            block: "start",//默认跳转到顶部
            behavior: "smooth"//滚动的速度
          });
        }
      }
    },
    onScroll(e) {
      let scrollItems = document.querySelectorAll(".scroll-item");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
            e.target.scrollTop >=
            scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          this.activeStep = i;
          break;
        }
      }
    },
  },
  data() {
    return {
      activeStep :0,
      title_list:[
        {title:'第一'},
        {title:'第二'},
        {title:'第三'},
        {title:'第四'},
        {title:'第五'},
      ]
    }
  }
}
</script>
//样式
<style scoped>
.list {
  width: 140px;
  height: 240px;
  margin-bottom: 20px;
  background: pink;
  position:fixed;
  right:20px;
  top:300px;
}
ul {
  width:140px;
  height: 40px;
  line-height: 40px;
  list-style: none;
}
li {
  /* float: left; */
  font-size: 30px;
}
li>span {
  cursor:pointer;
}
.result {
  width: 100%;
  height: 400px;
  overflow: scroll;
}
.scroll-item {
  width: 100%;
  height: 500px;
  margin-top:20px;
  background: yellow;
  /*`在这里插入代码片`*/
}
.scroll-item>span {
  font-size: 40px;
}
.scroll-item:first-child {
  height:1000px;
  margin-top: 0;
}
.scroll-item:last-child {
  height: 500px;
}
</style>
